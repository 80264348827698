/* .container ol,
.container ul {
  padding-left: 20px;
}

.container .react-mde {
  border: none;
}

.container .react-mde .mde-header {
  display: none;
}

.container .react-mde .mde-preview {
  min-height: auto !important;
}

.container .react-mde .mde-preview .mde-preview-content {
  padding: 0;
}

.container .react-mde .mde-preview .mde-preview-content h1,
.container .react-mde .mde-preview .mde-preview-content h2,
.container .react-mde .mde-preview .mde-preview-content h3,
.container .react-mde .mde-preview .mde-preview-content h4,
.container .react-mde .mde-preview .mde-preview-content h5,
.container .react-mde .mde-preview .mde-preview-content h6 {
  border-bottom: none;
} */

.rc-md-editor .editor-container .section .section-container {
  padding: 0 !important;
}
.rc-md-editor .editor-container .section {
  border: none !important;
}

.sec-html * {
  font-size: 16px !important;
}

pre code {
  display: inline-block;
  white-space: pre-wrap;
  width: '100%';
}

.blink {
  width: 12px;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
