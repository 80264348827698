/* @keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blink-animation {
    animation: blink-animation 1s infinite;
} */
:where(h1, h2, h3, h4, h5, h6) {
  font-weight: bold !important;
}

:where(h4) {
  font-size: 16px !important;
}
:where(h5) {
  font-size: 13px !important;
}
:where(h6) {
  font-size: 11px !important;
}

.button-type-underline,
.button-type-wrap {
  display: none !important;
}
