@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url(assets/fonts/Noto_Sans_JP/NotoSansJP-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
